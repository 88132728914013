import styled from 'styled-components';

export const PokemonCardContainer = styled.li`
    display: flex;
    flex: 0 0 33.333%;
    text-align: center;
    position: relative;
    padding: 5px;
    max-width: 33.333%;
    cursor: pointer;
    
    @media screen and (min-width: 768px) {
        flex: 0 0 25%;
        max-width: 25%;
    }
    @media screen and (min-width: 1024px) {
        flex: 0 0 20%;
        max-width: 20%;
    }
    @media screen and (min-width: 1280px) {
        flex: 0 0 16.667%;
        max-width: 16.667%;
    }
    @media screen and (min-width: 1440px) {
        flex: 0 0 12.5%;
        max-width: 12.5%;
    }
`;

export const PokemonCardContent = styled.div`
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    background-color: #596275;

    img {
        display: block;
        max-width: 100%;
    }
`;
import React from 'react';
import { IPokemon } from '../../types';
import getTypeName from '../../utils/getTypeName';
import { NumberContainer, NameEN, NameFR, TypesList, TypeItem } from './styles';

interface IPokemonItemProps {
    pokemon: IPokemon;
}

const PokemonItem = ({ pokemon }: IPokemonItemProps) => {
    const { id, name, types } = pokemon;
    const formattedId = id < 10 ? `00${id}` : id < 100 ? `0${id}` : id;

    const pokemonImage = require(`../../assets/pokemon/${formattedId}.png`);

    const typesList = types.map((typeId: number) => {
        const typeImage = require(`../../assets/types/${typeId}.png`);
        return typeImage && (
            <TypeItem key={`type-${typeId}`}><img src={typeImage.default} alt={getTypeName(typeId)} /></TypeItem>
        );
    });

    return (
        <>
            <NumberContainer>#{id}</NumberContainer>
            {pokemonImage && <img src={pokemonImage.default} alt={name.fr} />}
            <NameEN>{name.en}</NameEN>
            <NameFR>{name.fr}</NameFR>
            <TypesList>
                {typesList}
            </TypesList>
        </>
    )
}

export default PokemonItem;

import itemsListJSON from '../data/items.json';
import { ILangs, IItem } from '../types';

const getItemName = (itemId: number, lang: ILangs = 'en') => {
    const langSafe: ILangs = lang; 
    const itemsList: IItem[] = itemsListJSON;
    const itemFound = itemsList.filter((item: IItem) => item.id === itemId);

    return itemFound.length === 1 ? itemFound[0].name[langSafe] : "";
};

export default getItemName;
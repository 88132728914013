import React from 'react';
import { IPokemon } from '../../types';
import PokemonDetails from '../PokemonDetails'
import { PokemonCardContainer, PokemonCardContent } from './styles';

interface IPokemonCardProps {
    pokemon: IPokemon;
    selectPokemon: Function;
}

const PokemonCard = ({ pokemon, selectPokemon }: IPokemonCardProps) => {
    return (
        <PokemonCardContainer>
            <PokemonCardContent onClick={() => selectPokemon(pokemon)}>
                <PokemonDetails pokemon={pokemon} />
            </PokemonCardContent>
        </PokemonCardContainer>
    )
}

export default PokemonCard;

import styled from 'styled-components';

interface IPokemonDetailsContainerProps {
    visible: boolean;
}

export const PokemonDetailsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    padding: 20px;
    opacity: ${(props: IPokemonDetailsContainerProps) => props.visible ? '1' : '0'};
    visibility: ${(props: IPokemonDetailsContainerProps) => props.visible ? 'visible' : 'hidden'};
    transform: ${(props: IPokemonDetailsContainerProps) => props.visible ? 'scale(1)' : 'scale(0.9)'};
    transition: .2s all ease-in-out;
    z-index: 2;
    background-color: #303952;
`;

export const CloseButton = styled.button`
    appearance: none;
    border: 0;
    background-color: transparent;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;

    img {
        display: block;
        width: 25px;
    }
`;

export const EvolutionsList = styled.ul`
    max-width: 100%;
    max-height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow: auto;
`;

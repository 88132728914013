import React from 'react';
import { AlertContainer } from './styles';

interface IAlertProps {
    children: string;
}

const Alert = ({ children }: IAlertProps) => {
    return (
        <AlertContainer show={!!children}>
            {children}    
        </AlertContainer>
    )
}

export default Alert;

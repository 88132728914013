import React, { useState, useEffect, useCallback } from 'react';
import pokemonListJSON from '../../data/pokemon.json';
import { IPokemon } from '../../types';
import filterSearch from '../../utils/filterSearch';
import PokemonCard from '../PokemonCard';
import PokemonDetails from '../ModalDetails';
import closeIcon from '../../assets/icons/close.svg';
import { Title, ContentContainer, PokemonList, CustomInput, SearchContainer, ResetButton, NoPokemon, HeaderContainer } from './styles';
import getAllEvolutions from '../../utils/getAllEvolutions';
import Alert from '../Alert';

const Content = () => {
    const pokemonList: IPokemon[] = pokemonListJSON;
    const [search, setSearch] = useState("");
    const [selectedPokemon, setSelectedPokemon]: any = useState(null);
    const [alertMessage, setAlertMessage] = useState("");
    const [stickySearch, setStickySearch] = useState(false);

    const processSetSelectedPokemon = (pokemon: IPokemon) => {
        const evolutions = getAllEvolutions(pokemon);
        if (!evolutions) {
            setAlertMessage("No evolution for this Pokemon");
            setTimeout(() => setAlertMessage(""), 4000);
        } else {
            setSelectedPokemon(pokemon);
        }
    };

    const handleScroll = useCallback(() => {
        const offsetHeight = 0;
        if (window.scrollY > offsetHeight && !stickySearch) {
            setStickySearch(true);
        } else if (window.scrollY <= offsetHeight && stickySearch) {
            setStickySearch(false);
        }
    }, [stickySearch, setStickySearch]);

    useEffect(() => {
        document.addEventListener("scroll", handleScroll);
        return () => {
            document.removeEventListener("scroll", handleScroll);
        }
    }, [handleScroll]);

    const filteredList: JSX.Element[] = pokemonList
        .filter((pokemon: IPokemon) => filterSearch(search, pokemon))
        .map((pokemon: IPokemon) => (
            <PokemonCard
                key={`pokemon-${pokemon.id}`}
                pokemon={pokemon}
                selectPokemon={processSetSelectedPokemon}
            />
        ));

    return (
        <ContentContainer>
            <HeaderContainer stickySearch={stickySearch}>
                <Title>Pokedex GO</Title>
                <SearchContainer>
                    <CustomInput
                        type="text"
                        onInput={(e: React.FormEvent<HTMLInputElement>) => setSearch(e.currentTarget.value)}
                        placeholder="Search a Pokemon, a type or a number..."
                        value={search}
                    />
                    {search && <ResetButton onClick={() => setSearch("")}>
                        <img src={closeIcon} alt="Reset" />
                    </ResetButton>}
                </SearchContainer>
            </HeaderContainer>
            {filteredList.length > 0 ? <PokemonList>
                {filteredList}
            </PokemonList> : <NoPokemon>No Pokemon matching this search</NoPokemon>}
            <PokemonDetails pokemon={selectedPokemon} closePokemonDetails={setSelectedPokemon} />
            <Alert>{alertMessage}</Alert>
        </ContentContainer>
    )
}

export default Content;

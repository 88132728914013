import React from 'react';
import { IEvolutionDetailed } from '../../types';
import getItemName from '../../utils/getItemName';
import getLureName from '../../utils/getLureName';
import PokemonDetails from '../PokemonDetails';
import {
    EvolutionItemContainer,
    Level,
    ConditionsEvolution,
    ArrowsEvolution,
    PokemonDetailsContent,
    Candies,
    ConditionItem
} from './styles';
import arrowsIcon from '../../assets/icons/arrows.svg';

interface IEvolutionItemProps {
    evolutionDetailed: IEvolutionDetailed;
    level: number
}

const EvolutionItem = ({ evolutionDetailed, level }: IEvolutionItemProps) => {
    const candyImage = require(`../../assets/icons/candy.png`);
    return (
        <EvolutionItemContainer key={`evolution-${evolutionDetailed.id}`}>
            <Level>{level}</Level>
            <ConditionsEvolution>
                <ArrowsEvolution src={arrowsIcon} alt="" />
                {evolutionDetailed.conditions.candies && <Candies>
                    <img src={candyImage.default} alt="Candies" />
                    x{evolutionDetailed.conditions.candies}
                </Candies>}
                {evolutionDetailed.conditions.item && <ConditionItem>
                    +<img src={require(`../../assets/items/${evolutionDetailed.conditions.item}.png`).default} alt={getItemName(evolutionDetailed.conditions.item)} />
                </ConditionItem>}
                {evolutionDetailed.conditions.lure && <ConditionItem>
                    +<img src={require(`../../assets/lures/${evolutionDetailed.conditions.lure}.png`).default} alt={getLureName(evolutionDetailed.conditions.lure)} />
                </ConditionItem>}
                {evolutionDetailed.conditions.walk && <ConditionItem>
                    +&nbsp;<strong>{evolutionDetailed.conditions.walk}km</strong>
                </ConditionItem>}
                <ArrowsEvolution src={arrowsIcon} alt="" />
            </ConditionsEvolution>
            <PokemonDetailsContent>
                <PokemonDetails pokemon={evolutionDetailed} />
            </PokemonDetailsContent>
        </EvolutionItemContainer>
    )
};

export default EvolutionItem;

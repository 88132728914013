import styled from 'styled-components';

export const NumberContainer = styled.div`
    position: absolute;
    top: 10px;
    left: 10px;
    color: #303952;

    @media screen and (min-width: 768px) {
        font-size: 1.2rem;
    }
    @media screen and (min-width: 1024px) {
        font-size: 1.4rem;
    }
`;

export const NameEN = styled.div`
    font-size: 1.4rem;

    @media screen and (min-width: 768px) {
        font-size: 1.6rem;
    }
`;

export const NameFR = styled.div`
    font-size: 1.2rem;
    opacity: 0.8;
    font-weight: 200;

    @media screen and (min-width: 768px) {
        font-size: 1.4rem;
    }
`;

export const TypesList = styled.ul`
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 5px;
`;

export const TypeItem = styled.li`
    max-width: 20px;

    @media screen and (min-width: 768px) {
        max-width: 30px;
    }

    img {
        display: block;
        max-width: 100%;
    }
`;

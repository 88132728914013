import styled from 'styled-components';

export const EvolutionItemContainer = styled.li`
    position: relative;
    padding: 10px;
    margin: 5px;
    background-color: #596275;
    border-radius: 10px;
`;

export const ConditionsEvolution = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.4rem;
`;

export const ArrowsEvolution = styled.img`
    width: 20px;
    margin: 0 10px;
`;

export const Candies = styled.div`
    display: flex;
    align-items: center;
    
    img {
        display: block;
        width: 25px;
        margin-right: 10px;
    }
`;

export const Level = styled.div`
    background-color: #f7d794;
    border: 3px solid #f5cd79;
    color: #303952;
    border-radius: 15px;
    width: 30px;
    height: 30px;
    line-height: 24px;
    margin: 0 auto 10px;
`;

export const PokemonDetailsContent = styled.div`
    position: relative;

    > img {
        display: block;
        max-width: 100px;
        margin: auto;
    }
`;

export const ConditionItem = styled.div`
    display: flex;
    align-items: center;
    margin-left: 5px;
    font-weight: 200;

    strong {
        font-weight: 900;
    }
    img {
        display: block;
        height: 25px;
        margin-left: 5px;
    }
`;

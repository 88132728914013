import { IPokemon } from "../types";
import getTypeName from "./getTypeName";
import sanitizeValue from "./sanitizeValue";

const filterSearch = (search: string, pokemon: IPokemon) => {
    const cleanedSearch = sanitizeValue(search);

    const foundInTypes = pokemon.types.filter((typeId: number) => {
        const nameFR = sanitizeValue(getTypeName(typeId, "fr"));
        const nameEN = sanitizeValue(getTypeName(typeId, "en"));
        return nameFR.includes(cleanedSearch) || nameEN.includes(cleanedSearch);
    });

    return sanitizeValue(pokemon.name.fr).includes(cleanedSearch) 
        || sanitizeValue(pokemon.name.en).includes(cleanedSearch)
        || foundInTypes.length > 0
        || pokemon.id.toString().includes(cleanedSearch);
};

export default filterSearch;
import styled from 'styled-components';

interface IAlertContainerProps {
    show: boolean;
}

export const AlertContainer = styled.div<IAlertContainerProps>`
    position: fixed;
    bottom: 10px;
    left: 10px;
    right: 10px;
    background-color: #e77f67;
    z-index: 5;
    padding: 15px;
    border-radius: 5px;
    font-size: 1.4rem;
    opacity: ${props => props.show ? "1" : "0"};
    visibility: ${props => props.show ? "visible" : "hidden"};
    transform: ${props => props.show ? "translateY(0)" : "translateY(100%)"};
    transition: .2s all ease-in-out;
`;
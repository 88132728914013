import typesListJSON from '../data/types.json';
import { ILangs, IType } from '../types';

const getTypeName = (typeId: number, lang: ILangs = 'en') => {
    const langSafe: ILangs = lang; 
    const typesList: IType[] = typesListJSON;
    const typeFound = typesList.filter((type: IType) => type.id === typeId);

    return typeFound.length === 1 ? typeFound[0].name[langSafe] : "";
};

export default getTypeName;
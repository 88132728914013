import { IEvolution, IPokemon } from "../types";
import pokemonListJSON from '../data/pokemon.json';

const getAllEvolutions: any = (pokemon: IPokemon) => {
    const pokemonList: IPokemon[] = pokemonListJSON;
    if (pokemon.evolutions && pokemon.evolutions.length > 0) {
        const evolutionsToReturn: IPokemon[] = pokemon.evolutions.map((evolution: IEvolution) => {
            const pokemonFound: IPokemon = pokemonList.filter((pokemon: IPokemon) => pokemon.id === evolution.id)[0];
            return {
                ...pokemonFound,
                conditions: evolution.conditions
            };
        });
        return evolutionsToReturn.map((pokemonFromEvolution: IPokemon) => {
            if (pokemonFromEvolution.evolutions && pokemonFromEvolution.evolutions.length > 0) {
                pokemonFromEvolution.evolutions = getAllEvolutions(pokemonFromEvolution);
            }
            return pokemonFromEvolution;
        })
    }
}

export default getAllEvolutions;
import React, { useEffect, useState } from 'react';
import { IPokemon, IEvolutionDetailed } from '../../types';
import getAllEvolutions from '../../utils/getAllEvolutions';
import {
    PokemonDetailsContainer,
    CloseButton,
    EvolutionsList
} from './styles';
import closeIcon from '../../assets/icons/close.svg';
import EvolutionItem from '../EvolutionItem';

interface IModalDetailsProps {
    pokemon: IPokemon | null;
    closePokemonDetails: Function;
}

const getEvolutionsInfo = (nestedEvolutions: IEvolutionDetailed[], level: number) => {
    const itemsToReturn: JSX.Element[] = [];
    nestedEvolutions.forEach((evolution: IEvolutionDetailed) => {
        itemsToReturn.push(<EvolutionItem evolutionDetailed={evolution} level={level} />);
        if (evolution.evolutions && evolution.evolutions.length > 0) {
            itemsToReturn.push(...getEvolutionsInfo(evolution.evolutions as any, level + 1));
        }
    });
    return itemsToReturn;
}

const ModalDetails = ({ pokemon, closePokemonDetails }: IModalDetailsProps) => {
    const [nestedEvolutions, setNestedEvolutions] = useState<IEvolutionDetailed[]>([]);
    useEffect(() => {
        if (pokemon) {
            setNestedEvolutions(getAllEvolutions(pokemon));
        }
    }, [pokemon]);

    const evolutionsItems: JSX.Element[] | null = nestedEvolutions && nestedEvolutions.length > 0 ? getEvolutionsInfo(nestedEvolutions, 1) : null;

    return (
        <>
            <PokemonDetailsContainer visible={!!pokemon && nestedEvolutions && nestedEvolutions.length > 0}>
                <CloseButton onClick={() => closePokemonDetails(null)}>
                    <img src={closeIcon} alt="Close" />
                </CloseButton>
                <EvolutionsList>
                    {evolutionsItems}
                </EvolutionsList>
            </PokemonDetailsContainer>
        </>
    )
}

export default ModalDetails;
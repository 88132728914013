import styled from 'styled-components';

export const Title = styled.h1`
    font-size: 3rem;
    transition: font-size .3s ease-in-out;

    @media screen and (min-width: 768px) {
        font-size: 4rem;
    }
`;

export const ContentContainer = styled.div`
    text-align: center;
    
    &::after {
        content: "";
        position: fixed;
        height: 20px;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(180deg, rgba(48,57,82,0) 20%, rgba(48,57,82,1) 100%);
        z-index: 1;
        opacity: 0.8;
    }
`;

export const PokemonList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 5px;
`;

export const CustomInput = styled.input`
    width: calc(100% - 20px);
    appearance: none;
    border: 2px solid #f7d794;
    padding: 10px 15px;
    text-align: center;
    background-color: transparent;
    color: #f5cd79;
    border-radius: 5px;

    ::placeholder {
        font-family: 'Montserrat', sans-serif;
    }
`;

export const SearchContainer = styled.div`
    position: relative;
`;

export const ResetButton = styled.button`
    display: block;
    width: 31px;
    height: 31px;
    appearance: none;
    border: 0;
    background-color: transparent;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    padding: 8px;
    cursor: pointer;

    img {
        display: block;
        width: 100%;
    }
`;

export const NoPokemon = styled.div`
    font-size: 1.6rem;
    padding: 50px 30px;
`;

interface IHeaderContainerProps {
    stickySearch: boolean;
}

export const HeaderContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #303952;
    padding: 15px 0;
    transition: all .2s ease-in-out;
    z-index: 1;

    ${(props: IHeaderContainerProps) => props.stickySearch && `
        &::after {
            content: "";
            position: fixed;
            height: 20px;
            top: 105px;
            left: 0;
            right: 0;
            background: linear-gradient(0deg, rgba(48,57,82,0) 20%, rgba(48,57,82,1) 100%);
            z-index: 1;
            opacity: 0.8;
        }

        ${Title} {
            margin-top: 0;
            font-size: 2rem;
        }
    `}
`

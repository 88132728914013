import luresListJSON from '../data/lures.json';
import { ILangs, ILure } from '../types';

const getLureName = (lureId: number, lang: ILangs = 'en') => {
    const langSafe: ILangs = lang; 
    const luresList: ILure[] = luresListJSON;
    const lureFound = luresList.filter((type: ILure) => type.id === lureId);

    return lureFound.length === 1 ? lureFound[0].name[langSafe] : "";
};

export default getLureName;